import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var regionalSwitcherVariants = function regionalSwitcherVariants(_ref) {
  var _onChange = _ref.onChange,
      mode = _ref.mode,
      republicEuropeBaseUrl = _ref.republicEuropeBaseUrl;
  return [_objectSpread({
    name: 'Global',
    id: 'global',
    inlineName: 'global',
    titleName: 'Global'
  }, mode === 'cookie-only' ? {
    onChange: function onChange() {
      return _onChange('global');
    }
  } : {
    onChange: function onChange() {
      return _onChange('global');
    },
    url: '/'
  }), {
    name: 'US',
    id: 'us',
    inlineName: 'US',
    titleName: 'US',
    onChange: _onChange ? function () {
      return _onChange('us');
    } : undefined,
    needsConfirmation: true
  }, {
    name: 'Europe',
    id: 'europe',
    inlineName: 'europe',
    titleName: 'EU',
    needsConfirmation: true,
    onChange: undefined,
    url: republicEuropeBaseUrl
  }];
};
export var REGION_VALID_IDS = ['global', 'us', 'europe'];