import { IFlipperEnum } from '@r-client/data/graphql-types';
import { useGetFeatureToggleQuery } from '@r-client/shared/data/flags/remote';
export var useDashboardFeatureFlag = function useDashboardFeatureFlag() {
  var _useGetFeatureToggleQ = useGetFeatureToggleQuery({
    variables: {
      feature: IFlipperEnum.UserDashboard
    }
  }),
      dashboardFeatureFlagData = _useGetFeatureToggleQ.data,
      isDashboardFeatureFlagDataLoading = _useGetFeatureToggleQ.isLoading;

  return !isDashboardFeatureFlagDataLoading && (dashboardFeatureFlagData === null || dashboardFeatureFlagData === void 0 ? void 0 : dashboardFeatureFlagData.flipper.enabled);
};